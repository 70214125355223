import(/* webpackMode: "eager", webpackExports: ["InternalAIProvider"] */ "/app/node_modules/ai/rsc/dist/rsc-shared.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/ClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BlurredDots.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider","ToastViewport"] */ "/app/src/components/ui/toast.tsx");
